import { ACCESSIBILITY, isMD } from '@build/util/constants/constants.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
import Modal from '@build/components/modal/modal.js';
import gsap from 'gsap';

class ReelCarousel {
	// Adjust this number to change the time each slide is shown for (in seconds)
	static SLIDE_TIME_MILLISEC = 3000;
	static SLIDE_SPEED = 300;
	swiper: Swiper;
	players: any = [];
	private boundModalChange: (ev: string) => void;
	private boundMotionChange: (ev) => void;

	constructor(private element: HTMLElement) {
		// Ensure we don't re-initialise
		this.element.dataset.init = 'true';

		const swiperParent = this.element.querySelector(
			'.swiper-parent',
		) as HTMLElement;
		if (!swiperParent) return;
		const swiperWrapper = this.element.querySelector(
			'.swiper-wrapper',
		) as HTMLElement;
		if (!swiperWrapper) return;
		const cards = Array.from(
			swiperWrapper.querySelectorAll('.swiper-slide'),
		) as HTMLElement[];
		if (!cards.length) return;

		// Duplicate the set of cards if there's not enough to fill the screen width
		const margin = 0;
		const cardWidth = cards[0].getBoundingClientRect().width + margin;
		const screenWidth = swiperParent.getBoundingClientRect().width;
		let totalCardsWidth = cards.length * cardWidth;

		// If the content is hidden, it could loop forever so kill it now
		if (screenWidth + cardWidth < 1) return;

		while (totalCardsWidth < screenWidth + cardWidth) {
			cards.forEach((card) => {
				swiperWrapper.insertAdjacentHTML('beforeend', card.outerHTML);
			});
			totalCardsWidth =
				swiperWrapper.querySelectorAll('.swiper-slide').length * cardWidth;
		}
		// Give us some extras, just to be sure
		cards.forEach((card) => {
			swiperWrapper.insertAdjacentHTML('beforeend', card.outerHTML);
		});

		const nextArrow = isMD()
			? this.element.querySelector('.c-carousel__next')
			: this.element.previousElementSibling?.querySelector('.c-carousel__next');
		const prevArrow = isMD()
			? this.element.querySelector('.c-carousel__prev')
			: this.element.previousElementSibling?.querySelector('.c-carousel__prev');

		// Hook up swipe left/right actions, arrow buttons, and autoplay
		this.swiper = new Swiper(swiperParent, {
			modules: [Navigation, Autoplay],
			direction: 'horizontal',
			centeredSlides: true,
			speed: ACCESSIBILITY.reducedMotion ? 0 : ReelCarousel.SLIDE_SPEED,
			loop: true,
			slidesPerView: 'auto',
			slidesPerGroup: 1,
			slideToClickedSlide: false,
			preventClicks: true,
			updateOnWindowResize: true,
			autoplay: {
				delay: ReelCarousel.SLIDE_TIME_MILLISEC,
			},
			navigation: {
				nextEl: nextArrow as HTMLElement,
				prevEl: prevArrow as HTMLElement,
			},
		});

		// Start it stopped, we'll start it when it comes into view
		this.swiper.autoplay.stop();

		const observer = new IntersectionObserver(
			(entries) => {
				const entry = entries[0];
				if (entry.isIntersecting) {
					this.swiper.autoplay.start();
					this.pauseVideos(true);
					if (!swiperParent.dataset.showMeta) this.setupMetaData(swiperParent);
				} else {
					this.swiper.autoplay.stop();
				}
			},
			{ rootMargin: '0% 0% 0% 0%' },
		);

		observer.observe(swiperParent);

		// Toggle the autoplay based on motion settings
		const motionChange = (ev: string) => {
			if (ev === 'motion off') {
				this.swiper.autoplay.stop();
				this.swiper.params.speed = 0;
			} else {
				this.swiper.autoplay.start();
				this.swiper.params.speed = ReelCarousel.SLIDE_SPEED;
			}
		};
		this.boundMotionChange = motionChange.bind(this);
		EventDispatcher.subscribe(
			'AccessibilityToggle',
			['motion off', 'motion on'],
			this.boundMotionChange,
		);

		// Pause autoplay when mouse or focus enters
		this.element.addEventListener('mouseover', () => {
			this.swiper.autoplay.stop();
		});

		this.element.addEventListener('focusin', () => {
			this.swiper.autoplay.stop();

			// Find the focused slide
			const focusedSlide = swiperWrapper.querySelector(
				'.swiper-slide:focus-within',
			);

			if (focusedSlide) {
				// Get the margin of the focused slide so we can add it to the x position
				const marginLeft =
					parseFloat(
						window.getComputedStyle(focusedSlide as HTMLElement).marginLeft,
					) * 2;

				// Move the swiper to the focused slide
				gsap.set(swiperWrapper, {
					x: ((focusedSlide as HTMLElement).offsetLeft - marginLeft) * -1,
				});
			}
		});

		// Start autoplay when mouse or focus leaves
		this.element.addEventListener('mouseout', () => {
			if (!ACCESSIBILITY.reducedMotion) this.swiper.autoplay.start();
		});

		this.element.addEventListener('focusout', () => {
			if (!ACCESSIBILITY.reducedMotion) this.swiper.autoplay.start();
		});

		// We have to re-register the links on slides because swiper removes & re-adds them to document
		this.swiper.on('slidesUpdated', () => {
			swiperWrapper.querySelectorAll('a, button').forEach((el) => {
				el.removeAttribute('data-init');
			});
			requestAnimationFrame(() => {
				EventDispatcher.dispatch('Reel', 'slidesUpdated');
			});
		});

		// Start/end videos when slides change
		this.swiper.on('realIndexChange', () => {
			this.pauseVideos(true);
		});

		if (this.element.querySelectorAll('iframe').length) {
			this.setupYouTubeVideos();
		}

		this.boundModalChange = this.onModalChange.bind(this);
		EventDispatcher.subscribe(
			'Modal',
			['open', 'close'],
			this.boundModalChange,
		);
	}

	private onModalChange(ev: string) {
		const modal = Modal.getLastModal();
		const isUs = modal?.modalWrapper.contains(this.element);
		if (ev === 'open' && !isUs) {
			// A modal opened that doesn't contain this carousel, pause us
			// so it's not so busy in the background
			this.swiper.autoplay.stop();
			this.pauseVideos(false);
		} else if (ev === 'close' && !document.contains(this.element)) {
			// Our containing modal has been destroyed
			// Unsubscribe to modal events
			EventDispatcher.off('Modal', ['open', 'close'], this.boundModalChange);
			EventDispatcher.off(
				'AccessibilityToggle',
				['motion off', 'motion on'],
				this.boundMotionChange,
			);
			// Kill swiper
			this.swiper?.autoplay.stop();
			this.swiper?.destroy();
		} else if (ev === 'close' && (isUs || !modal)) {
			// After a modal close happened
			// This carousel is now either on the current modal, or on the homepage
			if (!ACCESSIBILITY.reducedMotion) {
				this.swiper.autoplay.start();
				this.pauseVideos(true);
			}
		}
	}

	private pauseVideos(playCurrent: boolean) {
		// Pause all video elements
		const currentVideo =
			this.swiper.slides[this.swiper.activeIndex].querySelector('video');
		this.element.querySelectorAll('video').forEach((vid) => {
			if (!playCurrent || currentVideo !== vid) {
				vid.pause();
				// eslint-disable-next-line no-param-reassign
				vid.currentTime = 0;
			}
			// Play current video (if one)
			if (playCurrent && currentVideo === vid && !ACCESSIBILITY.reducedMotion) {
				if (vid.paused) vid.play();
			}
		});

		// Pause all YouTube videos
		const iframe =
			this.swiper.slides[this.swiper.activeIndex].querySelector('iframe');
		this.players.forEach((player: any) => {
			if (player.getCurrentTime() > 0) {
				player.seekTo(0);
				player.pauseVideo();
			}
			// Play current (if one)
			if (
				playCurrent &&
				player.getIframe() === iframe &&
				!ACCESSIBILITY.reducedMotion
			) {
				player.playVideo();
				player.seekTo(0);
			}
		});
	}

	private setupYouTubeVideos() {
		// Load and configure the YouTube Frame API to allow playing/pausing video
		const apiReady = () => {
			this.element.querySelectorAll('iframe').forEach((iframe) => {
				try {
					new window.YT.Player(iframe, {
						events: {
							onReady: (event: any) => {
								// We may need to seekTo(0) as YouTube tracks where you were up to when you come back
								this.players.push(event.target);
							},
						},
					});
				} catch (ex) {
					console.error('Failed to initiate YouTube player', ex);
				}
			});
		};

		if (window.YT?.Player) {
			// Already loaded from previous Reel/carousel
			apiReady();
		} else {
			window.onYouTubeIframeAPIReady = apiReady;
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';
			document
				.getElementsByTagName('script')[0]
				.parentNode.insertBefore(
					tag,
					document.getElementsByTagName('script')[0],
				);
		}
	}

	private setupMetaData(parent: HTMLElement) {
		const swiperParent = parent;
		swiperParent.dataset.showMeta = 'true';
		swiperParent.querySelectorAll('video').forEach((video) => {
			video.setAttribute('preload', 'metadata');
		});
	}
}

export default ReelCarousel;
