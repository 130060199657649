import { ACCESSIBILITY, SearchBits } from '@build/util/constants/constants.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import gsap from 'gsap';

class Search {
	private listParent: HTMLElement;
	private keywordInput: HTMLInputElement;
	private tagsButton: HTMLSelectElement;
	private tagsParent: HTMLElement;
	private mediaSelect: HTMLSelectElement;
	private formElement: HTMLFormElement;
	private paginationUrl: string | null;
	private modalContent: HTMLElement;
	private loadingPlaceholder: string;
	private tween: gsap.core.Tween | gsap.core.Timeline;
	private aborter: AbortController;
	private clearSearch: HTMLElement;
	private searchTitle: HTMLElement;

	constructor(private element: HTMLElement) {
		this.element.dataset.init = 'true';
		// Check we've got the needed bits and save them to this.
		if (!this.hookUpElements()) return;

		// If we've been opened with a search link, update the initial state of the form
		const searchUrl = this.modalContent.getAttribute('data-search-url');
		this.setFormStateFromSearchUrl(searchUrl || '');

		// Add listeners to the form/filter elements and update search items on change
		this.mediaSelect.addEventListener('change', () => {
			this.updateItems();
		});
		this.formElement.addEventListener('submit', (e) => {
			e.preventDefault();
			this.updateItems();
			// Unfocus the input to hide the keyboard
			this.keywordInput.blur();
		});
		Array.from(this.tagsParent.querySelectorAll('input')).forEach((el) => {
			el.addEventListener('change', () => {
				// Toggle the tag selected state
				this.updateItems();
			});
		});
		this.clearSearch.addEventListener('click', (e) => {
			e.preventDefault();
			Array.from(this.tagsParent.querySelectorAll('input')).forEach((el) => {
				// eslint-disable-next-line no-param-reassign
				el.checked = false;
			});
			this.keywordInput.value = '';
			this.mediaSelect.value = 'all';
			this.updateItems();
		});

		// Listen for search URL change (when tags filter is updated)
		EventDispatcher.subscribe('global', 'searchUpdated', () => {
			this.setFormStateFromSearchUrl(
				this.modalContent.getAttribute('data-search-url') || '',
			);
			this.updateItems();
		});

		// Kick off an initial search if we opened with a query, and we don't already have results rendered (i.e. they came in on this URL)
		// (otherwise if opened from search button await their request)
		if (!this.listParent.querySelectorAll('article').length) {
			if (searchUrl) {
				this.updateItems();
			} else {
				this.toggleInitialMessage(true);
				this.tagsButton.dataset.modalSearchUrl = this.generateRequestUrl();
			}
		} else {
			// If they had opened with results pre-rendered (came in on URL)
			this.addPaginationListener();
		}
	}

	private hookUpElements() {
		// Where the items will be fetched and added to
		this.listParent = this.element.querySelector(
			'.c-search-results__list',
		) as HTMLElement;
		// The parent modal which tracks our loading state
		this.modalContent = this.element.closest('.modal__content') as HTMLElement;
		// Keywords form which we catch the submit of
		this.formElement = this.element.querySelector('form') as HTMLFormElement;
		// The keyword input box
		this.keywordInput = this.element.querySelector(
			'input[name="keywords"]',
		) as HTMLInputElement;
		// The tags filter open button
		this.tagsButton = this.element.querySelector(
			'.c-search-results__tags-button',
		) as HTMLSelectElement;
		// The parent of the tag buttons
		this.tagsParent = this.element.querySelector(
			'.c-search-results__tags',
		) as HTMLElement;
		// Clear search button
		this.clearSearch = this.element.querySelector(
			'.c-search-results__clear',
		) as HTMLElement;
		// The Media filter select box
		this.mediaSelect = this.element.querySelector(
			'select.c-search-results__media-filter',
		) as HTMLSelectElement;
		// A loading placeholder item to use as a template
		this.loadingPlaceholder =
			this.element.querySelector('.c-search-results__loading')?.outerHTML || '';
		// The search title for keywords
		this.searchTitle = this.element.querySelector(
			'.c-search-results__title',
		) as HTMLElement;

		// Ensure we have them all
		return (
			this.listParent &&
			this.modalContent &&
			this.formElement &&
			this.keywordInput &&
			this.tagsButton &&
			this.tagsParent &&
			this.clearSearch &&
			this.mediaSelect &&
			this.loadingPlaceholder &&
			this.searchTitle
		);
	}

	private setFormStateFromSearchUrl(searchUrl: string) {
		if (!searchUrl) return;
		const bits = Search.urlToBits(searchUrl);
		if (bits.keywords) {
			try {
				this.keywordInput.value = bits.keywords;
			} catch (e) {
				console.error('Failed to set search parameter', e);
			}
		}
		if (bits.tags) {
			this.tagsParent
				.querySelectorAll('input')
				.forEach((input: HTMLInputElement) => {
					// eslint-disable-next-line no-param-reassign
					input.checked = false;
				});
			bits.tags.forEach((tag) => {
				const input = this.tagsParent.querySelector(
					`input[value="${tag}"]`,
				) as HTMLInputElement;
				if (input) input.checked = true;
			});
		}
		if (bits.media) {
			// eslint-disable-next-line prefer-destructuring
			this.mediaSelect.value = bits.media;
		}
	}

	/**
	 * Fires off a search request
	 * @param paginationUrl optional specific URL to load (used for pagination)
	 */
	private updateItems(paginationUrl: string | undefined = undefined): void {
		// Cancel previous searches if they're still going
		if (this.aborter) this.aborter.abort();
		this.paginationUrl = paginationUrl || null;
		// Set aria loading state
		this.listParent.parentElement?.setAttribute('aria-busy', 'true');
		this.listParent.setAttribute('aria-label', 'Loading');

		// Add some loading animation, if we don't already have it
		if (!this.modalContent.classList.contains('c-search-modal--autoload')) {
			if (!this.paginationUrl) {
				// If we're replacing results, then fade out current items first
				this.tween = gsap.timeline();
				this.tween.to(this.listParent, {
					opacity: 0,
					onComplete: () => {
						// Clear out the list
						this.listParent.innerHTML = '';
						// Add back some loading placeholders
						this.modalContent.classList.add('c-search-modal--autoload');
						this.listParent.insertAdjacentHTML(
							'beforeend',
							this.loadingPlaceholder +
								this.loadingPlaceholder +
								this.loadingPlaceholder,
						);
					},
				});
				// Fade them back in
				this.tween.to(this.listParent, { opacity: 1 });
			} else {
				// Get rid of the Load More button
				this.listParent.querySelector('.search-results__pagination')?.remove();
				// There should still be some placeholders from earlier (just hidden)
				// We'll fade them back in
				const placeholders = Array.from(
					this.listParent.querySelectorAll('.c-search-results__loading'),
				) as HTMLElement[];
				placeholders.forEach((placeholder) => {
					placeholder.style.setProperty('opacity', '0');
					placeholder.classList.remove('hidden');
				});
				this.tween = gsap.to(placeholders, { opacity: 1 });
			}
		}

		// Allow cancelling the request
		this.aborter = new AbortController();
		const url = this.generateRequestUrl();
		// Update URL (but without lots of history to wade back through)
		if (!this.paginationUrl) {
			// We don't want to save the pagination URL, otherwise it'll just load the last page without the earlier pages
			this.updateHistory(url);
			this.tagsButton.dataset.modalSearchUrl = url;
		}
		// Fetch the search results
		fetch(this.paginationUrl || url, {
			signal: this.aborter.signal,
		})
			.then((response) => {
				// Non 200 response?
				if (!response.ok) {
					throw new Error(response.statusText);
				}
				return response.text();
			})
			.then((data) => {
				const parser = new DOMParser();
				const parsedContent = parser.parseFromString(data, 'text/html');
				// Only replace the content within the scroll container so we don't have to recreate the root SmoothScroll
				return parsedContent.querySelector(
					'.c-search-results__list',
				) as HTMLElement;
			})
			.then((content: HTMLElement) => {
				if (!content || !content.children) {
					throw new Error('Unexpected content returned from search');
				}
				this.afterFetchSuccess(content);
			})
			.catch((e) => {
				// If this is intentionally cancelled that's fine, otherwise notify issue
				if (!e.ABORT_ERR || e.code !== e.ABORT_ERR) {
					console.error('Failed to fetch search results:', e.message);
					this.showFailMessage();
				}
			});
	}

	private updateHistory(url: string) {
		// Sanity check
		if (window.history.state?.dataset.modalTarget === 'search-results') {
			const state = { ...window.history.state };
			state.dataset.modalSearchUrl = url;
			state.href = url;
			window.history.replaceState(state, '', url);
		}
	}

	/**
	 * Updates the display to the message for when no search terms/filters are yet given
	 * @param show true to show, false to hide
	 */
	private toggleInitialMessage(show: boolean): void {
		const el = this.element.querySelector('.c-search-results__no-search');
		if (!el) return;
		gsap.set(el, { opacity: show ? 0 : 1 });
		el.classList.toggle('hidden', !show);
		gsap.to(el, {
			opacity: show ? 1 : 0,
			delay: 0.2,
		});
	}

	/**
	 * Updates the display to show/hide the message for when there's no results
	 * @param show true to show, false to hide
	 */
	private toggleEmptyMessage(show: boolean): void {
		const emptyEl = this.element.querySelector('.c-search-results__empty');
		if (!emptyEl) return;
		gsap.set(emptyEl, { opacity: show ? 0 : 1 });
		emptyEl.classList.toggle('hidden', !show);
		gsap.to(emptyEl, {
			opacity: show ? 1 : 0,
			delay: 0.2,
		});
	}

	/**
	 * Updates the search title to show the search terms
	 * @param keywords the search terms
	 */
	private updateSearchTitle(keywords: string): void {
		this.searchTitle.textContent = keywords
			? `Search results for "${keywords}"`
			: 'Search results';
	}

	/**
	 * Updates the display to the load failed message
	 */
	private showFailMessage(): void {
		if (this.tween && this.tween.isActive()) this.tween.kill();
		this.toggleEmptyMessage(false);
		this.listParent.innerHTML = this.failMessage();
		gsap.set(this.listParent, { opacity: 1 });
		this.clearAriaLoading();
	}

	/**
	 * Shows the items, if fetch succeeded
	 * @param content the c-search-results__list parsed from the HTML result from SearchResults.ss
	 */
	private afterFetchSuccess(content: HTMLElement): void {
		// Hide the empty message
		this.toggleEmptyMessage(false);
		// Update the search title
		this.updateSearchTitle(this.keywordInput.value);
		// Hide old list (or just the loading items if we're loading more)
		const placeholders = this.listParent.querySelectorAll(
			'.c-search-results__loading',
		);
		// This tween takes priority (if we're still showing loading page, kill that)
		if (this.tween && this.tween.isActive()) this.tween.kill();
		this.tween = gsap.to(this.paginationUrl ? placeholders : this.listParent, {
			opacity: 0,
			onComplete: () => {
				// If we had the loading placeholder animation going, ensure we hide the placeholders now
				this.modalContent.classList.remove('c-search-modal--autoload');
				this.listParent
					.querySelectorAll('.c-search-results__loading')
					.forEach((el) => {
						el.remove();
					});

				// Remove all the old list content if we weren't loading paginated items
				if (!this.paginationUrl) {
					this.listParent.innerHTML = '';
				}

				Array.from(content.children).forEach((el) => {
					// Hide each item so we can fade them in
					gsap.set(el, { opacity: 0 });
					// Add them to the page
					this.listParent.appendChild(el);
				});

				this.addPaginationListener();

				// Show the parent element again (it'll look empty anyway)
				this.listParent.style.opacity = '1';

				// There aren't any results
				if (!this.listParent.querySelectorAll('article').length) {
					this.toggleEmptyMessage(true);
				}

				// Fade the items in (this can't be timeline because listParent.children change within this onComplete)
				this.tween = gsap.to(this.listParent.children, {
					opacity: 1,
					stagger: ACCESSIBILITY.reducedMotion ? 0 : 0.05,
					onComplete: () => {
						this.clearAriaLoading();
					},
				});

				// Let other components register e.g. internal-links
				EventDispatcher.dispatch('global', 'contentAdded');
			},
		});
	}

	private addPaginationListener() {
		// If there's a pagination button, add listener to load those results into here
		const loadMoreLink = this.listParent.querySelector('.search-results__next');
		if (loadMoreLink && loadMoreLink.getAttribute('href')) {
			loadMoreLink.addEventListener('click', (e) => {
				e.preventDefault();
				this.updateItems(loadMoreLink.getAttribute('href') || undefined);
			});
		}
	}

	private clearAriaLoading() {
		this.listParent.parentElement?.removeAttribute('aria-busy');
		this.listParent.removeAttribute('aria-label');
	}

	/**
	 * Text to display when loading has failed
	 * @returns string of HTML content
	 */
	private failMessage(): string {
		return `
			<div class="space-y-8 pt-40 px-8 text-center col-span-full">
				<h3>Search failed</h3>
				<p>Please refresh the page and try again</p>
			</div>
		`;
	}

	/**
	 * Creates the search request URL based on the state of the form
	 * @returns string
	 */
	private generateRequestUrl(): string {
		let keywords = '';
		try {
			keywords = encodeURIComponent(this.keywordInput.value.trim());
		} catch (e) {
			console.error('Invalid search parameter', e);
		}
		const checkedInputs = Array.from(
			this.tagsParent.querySelectorAll('input:checked'),
		) as HTMLInputElement[];
		const tags = checkedInputs.map((el) => el.value);
		const media = this.mediaSelect.value;
		return Search.bitsToUrl({ keywords, tags, media });
	}

	public static urlToBits(searchUrl: string): SearchBits {
		const url = decodeURIComponent(searchUrl);
		const keywords = url.match(/\/search\/(\w+)/);
		const tags = url.match(/[?&]tags=([\w\d,-]+)/);
		const media = url.match(/[?&]media=(\w+)/);
		return {
			keywords: keywords ? keywords[1] : '',
			tags: tags ? tags[1].split(',') : [],
			media: media ? media[1] : '',
		};
	}

	public static bitsToUrl(bits: SearchBits) {
		const { keywords, tags, media } = bits;
		return `/search/${keywords}?tags=${tags.join()}&media=${media}`;
	}
}

export default Search;
