import gsap from 'gsap';
import { LOCAL_STORAGE } from '@build/util/constants/constants.js';
import LocalStorageUtil from '@build/util/local-storage/local-storage.js';
import ThemeChanger from '@build/components/theme-changer/theme-changer.js';
import Toast from '@build/components/toast/toast.js';

class Poll {
	optionElements: NodeListOf<Element>;
	pollID: string;
	pollType: string;
	isPosting: boolean;
	spinner: any;
	clickHandler: (ev: Event) => void;

	constructor(private element: HTMLElement) {
		this.pollID = this.element.dataset.pollId as string;
		this.pollType = this.element.dataset.pollType as string;
		this.optionElements = this.element.querySelectorAll('.c-poll__option');

		// Boolean to prevent multiple clicks
		this.isPosting = false;

		this.clickHandler = (ev) => this.postVote(ev.target as HTMLElement);
		// this.clickHandler.bind(this);

		// Kick things off
		this.init();
	}

	init() {
		// Add a data attribute to the element to show it has been initialized
		// This is used to prevent the script from running multiple times on the same element
		this.element.dataset.init = 'true';

		// Check if poll has been completed already
		// If it has, then show the results
		const previouslySelectedValue = this.getLocalStoreValue();
		if (previouslySelectedValue) {
			// Show selected value (and load results if type is Text)
			this.updateToAnswered(previouslySelectedValue);
		}

		// Otherwise (or if it's an Icons poll) set up button listeners
		if (this.pollType === 'Icons' || !previouslySelectedValue) {
			// Add the click event to the button
			this.initEvents();
		}
	}

	private getLocalStorePolls(): { [key: string]: string } {
		// If we've not got a local store of completed polls, create it
		if (!LocalStorageUtil.getItem(LOCAL_STORAGE.polls)) {
			LocalStorageUtil.setItem(LOCAL_STORAGE.polls, '{}');
		}

		return (
			(LocalStorageUtil.getObject(LOCAL_STORAGE.polls) as {
				[key: string]: string;
			}) || {}
		);
	}

	private getLocalStoreValue() {
		// Find the matching poll id in local store, if exists
		const polls = this.getLocalStorePolls();
		return polls[this.pollID];
	}

	// Add the click event to each vote button
	private initEvents() {
		this.optionElements.forEach((option) => {
			option.addEventListener('click', this.clickHandler);
			option.removeAttribute('disabled');
		});
	}

	private removeEvents() {
		this.optionElements.forEach((option) => {
			option.removeEventListener('click', this.clickHandler);
			option.setAttribute('disabled', 'true');
		});
	}

	/**
	 * Vote for an option
	 * @param btn The button element that was clicked
	 */
	private async postVote(btn: HTMLElement) {
		// Stop them from submitting text more than once
		if (this.pollType === 'Text') this.removeEvents();

		// Don't do anything if we're already posting
		if (this.isPosting) return;

		// The ID of the option that was clicked
		const optionEl = btn.closest('.c-poll__option') as HTMLElement;
		const optionID = optionEl.dataset.optionId || '';

		// Have they already chosen this answer? Then trigger action again without posting
		if (this.getLocalStoreValue() === optionID) {
			this.doOptionAction(optionEl);
			return;
		}

		// Set the posting state to true
		this.isPosting = true;

		// Add loading state to button
		this.spinner = optionEl.querySelector('.c-spinner');
		// Set aria loading state
		this.spinner?.setAttribute('aria-busy', 'true');
		this.spinner?.setAttribute('aria-label', 'Loading');
		this.spinner?.classList.toggle('c-spinner--running');

		// URL to ping which increments the vote count
		const url = `${BASE_URL}/poll/vote/${this.pollID}/${optionID}`;
		await fetch(url, {
			method: 'POST',
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				// Successful response, so add the data to the object for localStorage
				// Read this fresh out of localStorage to ensure we've got the latest values
				const polls = this.getLocalStorePolls();

				// ID of the poll => ID of the option that was clicked
				polls[`${data.poll}`] = `${data.option}`;

				// Add the data to localStorage using the utility method which converts json to string and sets the item
				LocalStorageUtil.setObject(LOCAL_STORAGE.polls, polls);
			})
			.catch((error) => {
				Toast.show('Failed to submit poll, please refresh and try again');
				console.error('Poll: Error fetching data', 'error', error);
			});

		// We're done so allow posting again
		this.isPosting = false;

		// Update arias on completion
		this.spinner?.removeAttribute('aria-busy');
		this.spinner?.setAttribute('aria-label', 'Loaded');

		// Trigger the Tally call so buttons are removed and percentages are displayed
		this.updateToAnswered(optionID);
		this.doOptionAction(optionEl);
	}

	/**
	 * Change to the answered state (different for Text vs Icons polls)
	 */
	private updateToAnswered(selectedOptionID: string) {
		if (this.pollType === 'Icons') {
			(this.optionElements as NodeListOf<HTMLElement>).forEach(
				(option: HTMLElement) => {
					const el = option;
					this.toggleSelected(el, selectedOptionID === el.dataset.optionId);
				},
			);
			this.spinner?.classList.remove('c-spinner--running');
		} else {
			this.getVotes(selectedOptionID);
		}
	}

	private toggleSelected(el: HTMLElement, state: boolean) {
		// Add the selected class to option that was clicked
		el.classList.toggle('c-poll__option--selected', state);
		el.setAttribute('aria-pressed', state.toString());
	}

	/**
	 * Get the tallys for each option and add them to the element
	 */
	private async getVotes(selectedOptionID: string) {
		// The URL to ping to get the tallys
		const url = `${BASE_URL}/poll/tally/${this.pollID}`;

		const elementsToFadeOut = [
			...Array.from(this.element.querySelectorAll('.c-poll__option-label')),
			...Array.from(this.element.querySelectorAll('.c-spinner')),
		];

		await fetch(url)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				return response.json();
			})
			.then(async (data) => {
				let totalClicks = 0;

				// Loop over each returned data object to get the total number of clicks
				for (let i = 0; i < data.options.length; i++) {
					totalClicks += parseInt(data.options[i].clicks, 10);
				}

				// Loop over each option element within the poll
				(this.optionElements as NodeListOf<HTMLElement>).forEach(
					(option: HTMLElement) => {
						const el = option;
						const percentageLabel = el.querySelector(
							'.c-poll__option-percentage__label',
						) as HTMLElement;
						const percentageBar = el.querySelector(
							'.c-poll__option-percentage__bar',
						) as HTMLElement;
						// Get the ID of the option
						const id = el.dataset.optionId;

						// Find the object in the returned data that matches the option ID
						const foundObject = data.options.find(
							(obj: { id: string }) => obj.id === id,
						);

						// Calculate the percentage of clicks for this option
						const percentage =
							totalClicks > 0 && foundObject
								? Math.round((foundObject.clicks / totalClicks) * 100)
								: 0;

						percentageLabel.innerText = `${percentage}%`;
						percentageBar.dataset.percentage = `${percentage}`;

						this.toggleSelected(el, selectedOptionID === id);

						el.classList.add('c-poll__option--with-result');
						el.setAttribute('role', 'presentation');
					},
				);
			})
			.catch((error) => {
				Toast.show('Failed to load poll results, please refresh and try again');
				console.error('Poll: Error fetching data', 'error', error);
			});

		// Remove the transition so it doesn't interfere with gsap
		gsap.set(elementsToFadeOut, {
			transition: 'none',
		});

		// Show the percentage bars ready for animating width
		gsap.set(this.element.querySelectorAll('.c-poll__option-percentage__bar'), {
			opacity: 1,
		});

		// Use timeline for better control
		const tl = gsap.timeline();

		tl
			// Fade out the labels and spinner
			.to(elementsToFadeOut, {
				autoAlpha: 0,
				onComplete: () => {
					// remove the spinner
					this.element
						.querySelectorAll('.c-spinner--running')
						.forEach((el) => el.classList.remove('c-spinner--running'));
				},
			})
			// Fade the labels in
			.to(
				this.element.querySelectorAll(
					'.c-poll__option-label, .c-poll__option-percentage__label',
				),
				{
					autoAlpha: 1,
				},
			)
			// Animate the width of the percentage bars
			.to(
				this.element.querySelectorAll('.c-poll__option-percentage__bar'),
				{
					width: (i, el) => `${el.dataset.percentage}%`,
				},
				'<',
			);
	}

	private doOptionAction(el: HTMLElement) {
		// NB; Message action is handled directly via internal-links/modal (see Poll.ss)
		// Update the theme colour
		if (el.dataset.optionTheme) {
			ThemeChanger.toggleTheme(el.dataset.optionTheme);
		}
	}
}

export default Poll;
