import { ACCESSIBILITY } from '@build/util/constants/constants.js';
import Lenis from 'lenis';
import HeaderScrollBack from '@build/components/header-scrollback/header-scrollback.js';

/**
 * SmoothScroll
 * @description
 * A wrapper around Lenis to create a smooth scrolling effect
 */
class SmoothScroll {
	// The wrapper element
	public el: HTMLElement;
	// The content element
	public content: HTMLElement;
	// The Lenis instance
	public lenis: Lenis;
	// The unique identifier
	public id: number;

	// Static property to store all instances of SmoothScroll
	// eslint-disable-next-line no-use-before-define
	static instances: SmoothScroll[] = [];
	// Static property to generate unique identifiers
	static nextId = 0;

	static headerScrollback: HeaderScrollBack;

	constructor(wrapper?: HTMLElement, hideHeader: boolean = false) {
		if (!wrapper) return;

		this.el = wrapper;
		this.content = this.el.querySelector(
			'.smoothscroll__content',
		) as HTMLElement;

		// Create a new Lenis instance on the wrapper, scrolling the content
		this.lenis = new Lenis({
			wrapper: this.el,
			content: this.content,
			// Lower values create a smoother scroll effect
			lerp: ACCESSIBILITY.reducedMotion ? 1 : 0.15,
			// Enables smooth scrolling for mouse wheel events
			smoothWheel: true,
		});

		// Assign a unique identifier to this instance
		this.id = SmoothScroll.nextId++;

		// Keeps Lenis ticking over smoothly
		requestAnimationFrame((t) => this.raf(t));

		// Allows us to retreive this in other classes
		SmoothScroll.instances.push(this);

		// Set up the header nav to hide/show when this new content scrolls
		if (!SmoothScroll.headerScrollback) {
			SmoothScroll.headerScrollback = new HeaderScrollBack();
		}
		SmoothScroll.headerScrollback.init(this.lenis, hideHeader);
	}

	// Lenis RAF utility
	raf(time: number) {
		this.lenis.raf(time);
		requestAnimationFrame((t) => this.raf(t));
	}

	// Get the last instance of SmoothScroll
	static getLastSmoothScroll() {
		return SmoothScroll.instances[SmoothScroll.instances.length - 1];
	}

	static stopLast(hideHeader: boolean = false) {
		SmoothScroll.getLastSmoothScroll().lenis.stop();
		if (hideHeader) {
			SmoothScroll.headerScrollback.slideUp();
		} else {
			SmoothScroll.headerScrollback.slideDown();
		}
	}

	static restartLast(hideHeader: boolean = false) {
		SmoothScroll.getLastSmoothScroll().lenis.start();
		if (hideHeader) {
			SmoothScroll.headerScrollback.slideUp();
		} else {
			SmoothScroll.headerScrollback.slideDown();
		}
	}

	// Destroy a SmoothScroll instance by its unique identifier
	static destroyInstance(id: number) {
		const item = SmoothScroll.instances.find((instance) => instance.id === id);
		if (!item) return;
		item.lenis.destroy();
		SmoothScroll.instances = SmoothScroll.instances.filter((i) => i !== item);
	}
}

export default SmoothScroll;
