import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import { FOOTER, MOTION } from '@build/util/constants/constants.js';
import Modal from '@build/components/modal/modal.js';

class ShieldedSite {
	private element: HTMLElement | null;
	private timeout: number;

	constructor() {
		this.element = document.querySelector('.shielded-site button');

		if (!this.element) return;

		EventDispatcher.subscribe('Modal', 'contentAdded', () => {
			if (this.timeout) window.clearTimeout(this.timeout);

			// Get the current modal and its shielded site button
			const modal = Modal.getLastModal();
			const modalEl = modal?.modalContent;
			const shieldedSite = modalEl?.querySelector('.shielded-site');

			if (
				this.element &&
				modalEl &&
				shieldedSite &&
				!modalEl.contains(this.element)
			) {
				// Delay moving the button until modal content has faded in
				this.timeout = window.setTimeout(() => {
					// Remove the existing shielded site button if there is one
					shieldedSite.querySelector('button')?.remove();
					// Move the shielded site button into the modal footer
					if (this.element) {
						shieldedSite.append(this.element);
					}
				}, MOTION.duration.med * 1000);
			}
		});

		// Move the shielded site button into the previous modal or home page
		EventDispatcher.subscribe('Modal', 'closing', () => {
			if (this.timeout) window.clearTimeout(this.timeout);

			if (!this.element) return;

			// Get the current modal and its shielded site button
			const modal = Modal.getLastModal();
			const modalEl = modal?.modalContent;
			const shieldedSite = modalEl?.querySelector('.shielded-site');

			if (modalEl && shieldedSite) {
				if (!modalEl.contains(this.element)) {
					// Delay adding shielded site button until modal content has faded in
					this.timeout = window.setTimeout(() => {
						if (this.element) {
							shieldedSite.append(this.element);
						}
					}, MOTION.duration.med * 1000);
				}
			}
			// Move the shielded site button back into the homepage footer
			else if (this.element.closest('.modal')) {
				FOOTER.querySelector('.shielded-site button')?.remove();
				FOOTER.querySelector('.shielded-site')?.append(this.element);
			}
		});
	}
}

export default ShieldedSite;
